<template>
	<div class="page-wrap">
		<div class="page-header">
			<div class="search-wrap"><searchInput v-model="baseData.query" @search="searchEvent"></searchInput></div>
			<button type="button" class="filter-btn" @click="targetFilterEvent">
				筛选
				<van-icon name="arrow-down" />
			</button>
		</div>

		<filterPopup ref="filterPopup" height="50%" title="筛选" @onReset="onReset" @onConfirm="searchEvent">
			<div class="filter-container">
				<filterPopupItem label="设备科室">
					<div class="select-item" @click="tenantSelectEvent">{{ baseData.tenantName }}</div>
					<KcCascader
						ref="tenantSelect"
						v-model="baseData.tenantId"
						:options="baseOptions.tenantOptions"
						:props="{ label: 'name', value: 'id' }"
						@submit="tenantChange"
					></KcCascader>
				</filterPopupItem>

				<filterPopupItem label="设备分类">
					<div class="select-item" @click="categorySelectEvent">{{ baseData.categoryName }}</div>
					<KcCascader
						ref="categorySelect"
						v-model="baseData.categoryId"
						:options="baseOptions.categoryOptions"
						:props="{ label: 'name', value: 'id' }"
						@submit="categoryChange"
					></KcCascader>
				</filterPopupItem>
			</div>
		</filterPopup>

		<div class="page-container">
			<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
				<div class="main-wrap">
					<div class="card-list">
						<listItem1 v-for="(item, index) in tableData" :key="index" :itemData="item" :config="listItemConfig">
<!--							<template slot="titleRight">-->
<!--								<van-rate color="#ffd21e"-->
<!--                          void-icon="star"-->
<!--                          void-color="#eee" v-model="item.rate || 0" :size="12" />-->
<!--							</template>-->
							<div class="row-footer" slot="footer"><button type="button" class="button-plan button-plan-primary" @click="cancelShare(item)">取消共享</button></div>
						</listItem1>
					</div>

					<KcEmpty v-if="!tableData.length"></KcEmpty>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
import listItem1 from '@components/listItem/listItem1';
import { getDate } from '@utils/utils.js';
import tenantBaseDataMixinAll from '@/mixins/tenantMixins/tenantBaseDataMixin-all.js';
export default {
	name: 'shareDevice',
	components: { listItem1 },
	provide() {
		return {
			shareOrderBaseData: this.baseData
		};
	},
	mixins: [tenantBaseDataMixinAll],
	data() {
		return {
			baseData: {
				query: '',
				tenantId: '',
				tenantName: '全部科室',
				categoryId: '',
				categoryName: '全部分类'
			},
			baseOptions: {
				tenantOptions: [
					{
						id: '',
						name: '全部科室'
					}
				],
				categoryOptions: [
					{
						id: '',
						name: '全部分类'
					}
				]
			},

			isRefresh: false,

			listItemConfig: {
				titleKey: 'deviceName',
				layout1: [],
				layout2: [
					{
						label: this.$VUEAPPDEVICECODE(),
						key: 'code'
					},
					{
						label: '卡片号',
						key: 'label'
					},
					{
						label: '设备分类',
						key: 'categoryName'
					},
					{
						label: '品牌',
						key: 'brandName'
					},
					{
						label: '型号',
						key: 'modelName'
					},
					{
						label: '科室',
						key: 'tenant'
					}
				]
			},
			totalCount: 0,
			tableData: []
		};
	},
	created() {
		this.getDeviceCategoryTree();
		this.getList();
	},
	mounted() {},
	methods: {
		targetFilterEvent() {
			this.$refs.filterPopup.show();
		},
		onReset() {
			this.baseData.tenantId = '';
			this.baseData.categoryId = '';
			this.baseData.tenantName = '全部科室';
			this.baseData.categoryName = '全部分类';
		},
		searchEvent() {
			this.$refs.filterPopup.hide();
			this.getList();
		},
		tenantSelectEvent() {
			this.$refs.tenantSelect.show();
		},
		tenantChange(e, item) {
			this.baseData.tenantName = item.name;
		},
		getDeviceCategoryTree() {
			this.$api.assetModule
				.getDeviceCategoryTree()
				.then(res => {
					res.data.unshift({
						id: '',
						name: '全部分类'
					});
					this.baseOptions.categoryOptions = res.data;
				})
				.catch(() => {});
		},
		categorySelectEvent() {
			this.$refs.categorySelect.show();
		},
		categoryChange(e, item) {
			this.baseData.categoryName = item.name;
		},

		onRefresh() {
			this.isRefresh = true;
			this.getList(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getList(callbak) {
			let userInfo = this.$store.getters.userInfo;
			this.$api.deviceWorkManager
				.shareListForWeb({
					query: this.baseData.query,
					page: 1,
					pageSize: 1000,
					tenantIds: this.baseData.tenantId,
					categoryIds: this.baseData.categoryId,
					isTenantIdChild: 1,
					startTime: getDate() + ' 00:00:00',
					endTime: getDate() + ' 23:59:59',
					timeType: 1
				})
				.then(res => {
					res.data.map(item => {
						item.orderTimeText = this.$filters.formatDateTime(item.orderTime);
						item.startTimeText = this.$filters.formatDateTime(item.startTime);
					});
					this.tableData = res.data;
					if (typeof callbak == 'function') callbak();
				})
				.catch(e => {
					if (typeof callbak == 'function') callbak();
				});
		},
		cancelShare(item) {
			this.$dialog
				.confirm({
					message: '是否取消共享该设备？'
				})
				.then(() => {
					this.$api.deviceWorkManager
						.deviceAddOrMoveShare({
							deviceId: item.deviceId,
							share: 0
						})
						.then(res => {
							this.$toast.success('已取消');
							this.getList();
						})
						.catch(e => {
							this.$toast.fail('操作失败');
						});
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.page-wrap {
	height: 100%;
	background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
	background-size: 100% 195px;
	background-repeat: no-repeat;

	.page-header {
		margin-bottom: 12px;
		padding: 8px 16px 0 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.search-wrap {
			width: calc(100% - 50px);
		}
		.filter-btn {
			font-size: 12px;
			color: #fff;
			background: none;
			line-height: 32px;
			.van-icon {
				margin-left: 4px;
			}
		}
	}

	.page-container {
		height: calc(100% - 52px - 10px);
		overflow-y: auto;
		.main-wrap {
		}
	}
}

.filter-container {
	.select-item {
		display: inline-block;
		line-height: 30px;
		background: #d8e3ff;
		color: #3e73fb;
		border: 1px solid #3e73fb;
		border-radius: 32px;
		padding: 0 20px;
		max-width: 100%;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
</style>
